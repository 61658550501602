import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.TELEGRAM_X]: 'telegramx',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token) {
      return `https://d3h9tj8dk4i8g5.cloudfront.net/images/tokens/${getAddress(token.address)}.png`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export default getTokenLogoURL
